import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { XpoAllowCharactersModule, XpoLtlPipeModule, XpoLtlSicSwitcherModule } from '@xpo-ltl/ngx-ltl';
import { XpoCellRenderersModule } from '@xpo-ltl/ngx-ltl-board';
import {
  XpoBusyLoaderModule,
  XpoButtonModule,
  XpoCheckboxModule,
  XpoContextualHeaderModule,
  XpoDatePickerModule,
  XpoIconModule,
  XpoInlineSearchModule,
  XpoLtlPopoverModule,
  XpoSsiModule,
  XpoStatusIndicatorModule,
  XpoTabsModule,
  XpoTileRibbonModule,
} from '@xpo-ltl/ngx-ltl-core';
import { MaterialModule } from '../material/material.module';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { AutocompleteChipsFilterComponent } from './components/autocomplete-chips-filter/autocomplete-chips-filter.component';
import { AppContextualHeaderComponent } from './components/contextual-header/contextual-header.component';
import { DoorPlanProfileFilterComponent } from './components/door-plan-profile-filter/door-plan-profile-filter.component';
import { FreightFlowInfoPopoverComponent } from './components/freight-flow-path/freight-flow-info-popover/freight-flow-info-popover.component';
import { FreightFlowPathLegComponent } from './components/freight-flow-path/freight-flow-load-leg/freight-flow-load-leg.component';
import { FreightFlowPathMoveLegComponent } from './components/freight-flow-path/freight-flow-move-leg/freight-flow-move-leg.component';
import { FreightFlowPathComponent } from './components/freight-flow-path/freight-flow-path.component';
import { ShiftFilterComponent } from './components/shift-filter/shift-filter.component';
import { SicSwitcherWrapperComponent } from './components/sic-switcher-wrapper/sic-switcher-wrapper.component';
import { XpoPaginatorComponent } from './components/xpo-paginator/xpo-paginator.component';
import { AppElementFocusOnEventDirective } from './directives/element-focus-on-event.directive';
import { SicDescriptionTooltipDirective } from './directives/sic-description-tooltip.directive';
import { ActiveSicPipe } from './pipes/active-sic/active-sic.pipe';
import { DoorNumberPipe } from './pipes/door-number/door-number.pipe';
import { FreightFlowShiftPipe } from './pipes/shift-code/shift-code.pipe';
import { SicControlsPipe } from './pipes/sic-controls-filter-menu/sic-controls-filter-menu.pipe';
import { TrailerNumberPipe } from './pipes/trailer-number/trailer-number.pipe';
import { TrailerStatusPipe } from './pipes/trailer-status/trailer-status.pipe';
import { BannerAlertComponent, BannerAlertContentComponent } from './components/banner-alert';

const xpoModules = [
  XpoBusyLoaderModule,
  XpoButtonModule,
  XpoCellRenderersModule,
  XpoCheckboxModule,
  XpoContextualHeaderModule,
  XpoDatePickerModule,
  XpoAllowCharactersModule,
  XpoLtlPipeModule,
  XpoIconModule,
  XpoSsiModule,
  XpoInlineSearchModule,
  XpoLtlPopoverModule,
  XpoLtlSicSwitcherModule,
  XpoStatusIndicatorModule,
  XpoTabsModule,
  XpoTileRibbonModule,
];

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FreightFlowPathComponent,
    FreightFlowPathMoveLegComponent,
    FreightFlowPathLegComponent,
    FreightFlowInfoPopoverComponent,
    AppContextualHeaderComponent,
    DoorPlanProfileFilterComponent,
    ShiftFilterComponent,
    AutocompleteChipsFilterComponent,
    SicSwitcherWrapperComponent,
    // Pipes/Directives
    AppElementFocusOnEventDirective,
    FreightFlowShiftPipe,
    SicControlsPipe,
    SicDescriptionTooltipDirective,
    XpoPaginatorComponent,
    DoorNumberPipe,
    ActiveSicPipe,
    TrailerStatusPipe,
    TrailerNumberPipe,
    BannerAlertComponent,
    BannerAlertContentComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, ...xpoModules],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ...xpoModules,
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FreightFlowPathComponent,
    FreightFlowPathMoveLegComponent,
    FreightFlowPathLegComponent,
    FreightFlowInfoPopoverComponent,
    AppContextualHeaderComponent,
    DoorPlanProfileFilterComponent,
    ShiftFilterComponent,
    AutocompleteChipsFilterComponent,
    SicSwitcherWrapperComponent,
    BannerAlertComponent,
    BannerAlertContentComponent,

    // Pipes/Directives
    AppElementFocusOnEventDirective,
    SicDescriptionTooltipDirective,
    SicControlsPipe,
    XpoPaginatorComponent,
    DoorNumberPipe,
    ActiveSicPipe,
    TrailerStatusPipe,
    TrailerNumberPipe,
  ],
  entryComponents: [],
  providers: [TitleCasePipe], // MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
