import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  Unsubscriber,
  XpoLtlAppSwitcherService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import { XpoAppSwitcherApplication, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  map,
  retryWhen,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import { DynamicScriptLoaderService } from '@services/dynamic-script-loader/dynamic-script-loader.service';
import { AccountUrls } from '@shared/enums/account-urls.enum';
import { AppParams, AppRoutes, AppRoutesLabels } from '@shared/enums/app-routes.enum';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { UserRoleService } from '@shared/services/user-role/user-role.service';
import { CdtStateService } from './cross-dock-trailers/services/cross-dock-state.service';
import { DoorPlanListService } from './door-plan-list/services/door-plan-list-service.service';
import { InspectletInfoEnum } from './shared/enums/inspectlet-info.enum';
import { LocalStorageService } from './shared/services/local-storage.service';
import { NavigationService } from './shared/services/navigation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  isAuthorized$: Observable<boolean>;
  hideShell$: Observable<boolean>;
  readonly AccountUrls = AccountUrls;

  unsubscriber = new Unsubscriber();

  constructor(
    private configManagerService: ConfigManagerService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: XpoLtlLoggedInUserService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private authService: XpoLtlAuthenticationService,
    private userRoleService: UserRoleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private xpoAuthService: XpoAuthenticationService,
    public doorPlanListService: DoorPlanListService,
    private localStorageService: LocalStorageService,
    private cdtStateService: CdtStateService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.loadInspectlet(region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      /** Initialize SIC switcher by getting logged in user's sic */
      this.routes = [
        // {
        //   label: AppRoutesLabels.DOOR_PLAN_PAGE,
        //   path: `/${AppRoutes.DOOR_PLAN_PAGE}`,
        //   queryParamsHandling: 'merge',
        // },
        // {
        //   label: AppRoutesLabels.PLT_TRAILER_MANIFEST,
        //   path: `${AppRoutes.PLT_TRAILER_MANIFEST}`,
        // },
        {
          label: AppRoutesLabels.LOAD_LANE_SUMMARY,
          path: `/${AppRoutes.LOAD_LANE_SUMMARY}`,
          queryParamsHandling: 'merge',
        },
        // {
        //   label: AppRoutesLabels.DIVERSION_LANE,
        //   path: `/${AppRoutes.DIVERSION_LANE}`,
        //   queryParamsHandling: 'merge',
        // },
        // {
        //   label: AppRoutesLabels.SLC_PAGE,
        //   path: `/${AppRoutes.SLC_PAGE}`,
        //   queryParamsHandling: 'merge',
        // },
        // commenting this Nav item out for now(EFM-1876) confirmed with Chandra
        // {
        //   label: AppRoutesLabels.CROSS_DOCK_TRAILERS,
        //   path: `/${AppRoutes.CROSS_DOCK_TRAILERS}`,
        //   queryParamsHandling: 'merge',
        // },
      ];

      /** Shell setup */
      this.title = 'Dock Ops';
      this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    });
    this.handleLoggedInUser();
  }

  ngOnInit(): void {
    this.handleShellParam();
    this.navigationService.startTracking();
    this.localStorageService.clearExpiredData();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  trackByRoute(index: number, route: XpoShellRoute): string {
    return route?.path;
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    // NEED TO WAIT UNTIL AUTH SERVICE HAS COMPLETED SSO LOGIN BEFORE ANY ADDITIONAL CALLS ARE MADE
    this.xpoAuthService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loginService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
                .pipe(
                  retryWhen((errors) => errors.pipe(delay(1000), take(5))),
                  catchError((error) => {
                    console.error('loggedInUserFunc ERROR:', error);
                    this.userRoleService.setUserLoggedIn(false);
                    this.isAuthorized$ = of(false);
                    return of(undefined);
                  })
                );
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((user: User) => {
        if (user) {
          this.userRoleService.user = user;
          this.userRoleService.setUserLoggedIn(true);
          this.populateAppSwitcher();
          this.initializeDynatrace(user);
        } else {
          this.userRoleService.setUserLoggedIn(false);
        }
        this.isAuthorized$ = this.authService.isAuthorized();
      });
  }

  private handleShellParam(): void {
    this.hideShell$ = this.route.queryParams.pipe(map((params) => !!params[AppParams.HIDE_SHELL]));
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }

  /** Inspectlet Asynchronous Code */
  private loadInspectlet(region: string): void {
    // Only should be load in prod (wave)
    if (region !== 'wave') {
      return;
    }
    window[InspectletInfoEnum.INSPECTLET_PROP] = window[InspectletInfoEnum.INSPECTLET_PROP] || [];
    window[InspectletInfoEnum.INSPECTLET_PROP].push([
      InspectletInfoEnum.INSPECTLET_WID_PROP,
      InspectletInfoEnum.INSPECTLET_WID_VALUE,
    ]);
    const source = `${InspectletInfoEnum.INSPECTLET_URL}${Math.floor(new Date().getTime() / 3600000)}`;

    this.dynamicScriptLoader
      .load({
        name: `${InspectletInfoEnum.INSPECTLET_NAME}`,
        src: source,
        async: true,
      })
      .subscribe();
  }

  refresh(): void {
    this.doorPlanListService.refresh();
    this.cdtStateService.refresh();
  }

  onNavItemClicked(routePath: string) {
    if (routePath === AppRoutes.PLT_TRAILER_MANIFEST) {
      window.open(this.configManagerService.getSetting<string>(ConfigManagerProperties.pltAppLink));
    }
  }
}
