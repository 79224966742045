import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { BannerAlertComponent } from '../banner-alert.component';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { filter, takeUntil } from 'rxjs/operators';
import { NavbarFiltersService } from '@app/shared/services/navbar-filters-service';
import { FreightFlowShift } from '@app/shared/enums/shifts';

@Component({
  selector: 'app-banner-alert-content',
  templateUrl: './banner-alert-content.component.html',
  styleUrls: ['./banner-alert-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerAlertContentComponent implements OnInit, OnDestroy {
  fosUrl$ = new BehaviorSubject<string>('');
  private unsubscribe$ = new Subject<void>();

  @Input()
  level: 'warn' | 'critical' | null = 'warn';

  @HostBinding('class')
  get colorClasses(): string {
    switch (this.level) {
      case 'warn':
        return 'app-BannerAlertContent--warn';

      case 'critical':
        return 'app-BannerAlertContent--critical';
    }
  }

  constructor(
    private parentBannerComponent: BannerAlertComponent,
    private configService: ConfigManagerService,
    private filtersService: NavbarFiltersService,
  ) {}

  ngOnInit(): void {
    this.fosUrl$.next(`${this.configService.getSetting('efosAppUrl')}/dock/dock-manager`)

    combineLatest([this.filtersService.selectedSic$, this.filtersService.selectedShift$])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !!this.filtersService.selectedSic && !!this.filtersService.selectedShift)
      )
      .subscribe(([sic, shift]) => this.fosUrl$.next(`${this.configService.getSetting('efosAppUrl')}/dock/dock-manager?sic=${sic ?? ''}&shift=${FreightFlowShift[shift] ?? ''}`));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  hideBanner(): void {
    this.parentBannerComponent.toggleVisibility(false);
  }
}
