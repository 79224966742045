import { EventEmitter } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-banner-alert',
  templateUrl: './banner-alert.component.html',
  styleUrls: ['./banner-alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerAlertComponent {

  private contentVisible = true;

  @HostBinding('style.display')
  get visibility(): string {
    return this.contentVisible ? 'flex' : 'none';
  }

  @Output()
  toggleBanner: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleVisibility(isVisible: boolean) {
    this.toggleBanner.next(isVisible);
    this.contentVisible = isVisible;
  }

}
